import React, { useState, useEffect } from 'react';
import '../../../App.css';
import Translator from '../../../Components/Translator';
import { retrieveLaunchParams } from '@tma.js/sdk';

function HotelPage() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    const [hotelsData, setHotelsData] = useState([]);

    let subId = 'testSubId';

    useEffect(() => {
        fetchDataFromBackend();
    }, []);

    const fetchDataFromBackend = async () => {
        try {
            const response = await fetch('https://slmp.riccardo-morreti.ru/backend/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "class": "TRAVELS",
                    "type": "GET",
                    "data": {
                        "type": "1"
                    }
                })
            });

            const data = await response.json();
            const decodedData = JSON.parse(data.return.replace(/\\"/g, '"'));
            setHotelsData(decodedData);
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    };

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (event) => {

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <div className='Page'>
            <div className="MainPage-menu">
                <div className="Header">
                    <div className="header-container">
                        <div className="header-container-item"><Translator text="Отели" language={tLanguage} /></div>
                    </div>
                </div>
                <div className="MainPage-menu gap">
                    <div className="container full">
                        {hotelsData.map((hotel, index) => (
                            <div className="MainPage-menu-item hotels" key={index}>
                                <div className="logo">
                                    <img src={hotel.photo} alt={hotel.title} />
                                </div>
                                <div className="block-title-text">
                                    <div className="title"><Translator text={hotel.title} language={tLanguage} /></div>
                                    <div className="title-text"><Translator text={hotel.description} language={tLanguage} /></div>
                                </div>
                                <div className="block-limit-text">
                                    <div className="limit"><Translator text="Ограничения" language={tLanguage} /></div>
                                    <div className="limit-text"><Translator text={hotel.limits} language={tLanguage} /></div>
                                </div>
                                <div className="buttons">
                                    <div className="button-white">
                                    <Translator text="Промокоды" language={tLanguage} />
                                    </div>
                                    <a href={`${hotel.url}?subid=${subId}`}>
                                        <div className="button">
                                        <Translator text="Активировать" language={tLanguage} />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HotelPage;