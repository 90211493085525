import React from 'react';
import '../../App.css';
import { retrieveLaunchParams } from '@tma.js/sdk';

function CatalogPage() {

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (event) => {

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const requestData = {
            class: formData.get('handleType'),
            type: "INSERT",
            data: {
                title: formData.get('title'),
                description: formData.get('description'),
                limits: formData.get('limits'),
                url: formData.get('url'),
                photo: formData.get('photo'),
                type: formData.get('type')
            }
        };

        try {
            const response = await fetch('https://slmp.riccardo-morreti.ru/backend/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            switch (data.errorCode) {
                case 'INVALID_CLASS_REQUEST':
                    console.error('Invalid class');
                    break;
                case 'INVALID_CLASSTYPE_REQUEST':
                    console.error('Invalid classType');
                    break;
                case 'INVALID_TYPE_REQUEST':
                    console.error('Invalid type');
                    break;
                case 'INVALID_DATA_INSERT':
                    console.error('Invalid data for INSERT');
                    break;
                case 'INSERT_ERROR':
                    console.error('Critical error during INSERT');
                    break;
                case 'INSERT_OK':
                    console.log('Data inserted successfully');
                    break;
                default:
                    console.error('Unknown error:', data.errorCode);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className='Page admin'>
            <div className="MainPage-menu">
                <div className="container full">
                    <div className="gaps">
                        <div className="MainPage-menu admin">
                            <div className="title">Путешествия</div>
                            <div className="text-adm">Добавление нового</div>
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name='handleType' value='TRAVELS' />
                                <input type="text" name='title' placeholder='Заголовок' />
                                <input type="text" name='description' placeholder='Описание' />
                                <input type="text" name='limits' placeholder='Ограничения' />
                                <input type="text" name='url' placeholder='Ссылка' />
                                <input type="text" name='photo' placeholder='Фото (Строго URL)' />
                                <select name="type" id="typescript">
                                    <option value="1">Отель</option>
                                    <option value="2">Авиабилеты</option>
                                </select>
                                <button type="submit" className='button'>Добавить</button>
                            </form>
                        </div>
                        <div className="MainPage-menu admin">
                            <div className="title">Магазины</div>
                            <div className="text-adm">Добавление нового</div>
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name="handleType" value="SHOPS" />
                                <input type="text" name='title' placeholder='Заголовок' />
                                <input type="text" name='description' placeholder='Описание' />
                                <input type="text" name='limits' placeholder='Ограничения' />
                                <input type="text" name='url' placeholder='Ссылка' />
                                <input type="text" name='photo' placeholder='Фото (Строго URL)' />
                                <select name="type" id="typescript">
                                    <option value="1">Косметика</option>
                                    <option value="2">Электроника</option>
                                    <option value="3">Одежда и обувь</option>
                                    <option value="4">Аксессуары</option>
                                    <option value="5">Мероприятия</option>
                                    <option value="6">Детям</option>
                                    <option value="7">18+</option>
                                    <option value="8">Здоровье</option>
                                    <option value="9">Почитать</option>
                                    <option value="10">Поесть</option>
                                    <option value="11">Все для дома</option>
                                    <option value="12">Разное</option>
                                </select>
                                <button type="submit" className='button'>Добавить</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatalogPage;