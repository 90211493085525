import React from 'react';
import '../../../App.css';
import Translator from '../../../Components/Translator';
import { retrieveLaunchParams } from '@tma.js/sdk';
import like from '../Images/thumb-up-dynamic-color.svg';
import flash from '../Images/flash-dynamic-color.svg';

function TravelPage() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (event) => {
        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const haptic = (event) => {
        HapticFeedback.notificationOccurred('success');
    };

    const error = () => {
        HapticFeedback.notificationOccurred('error');
    }

    return (
        <div className='Page' onClick={redirectToPage}>
            <div className="MainPage-menu">
                <div className="Header">
                    <div className="header-container">
                        <div className="header-container-item"><Translator text="Путешествия" language={tLanguage} /></div>
                    </div>
                </div>
                <div className="MainPage-menu gap">
                    <div className="container full">
                        <div className="MainPage-menu-item travel">
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={like} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Отели" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/hotels'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel">
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Авиабилеты" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/airtickets'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item other">
                            <div className="icontainer-start-travel">
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Другое" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button">
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TravelPage;