import React, { useState, useEffect } from 'react';

const Translator = ({ text, language }) => {
    const [translatedText, setTranslatedText] = useState('');

    const translateText = async () => {
        try {
            const response = await fetch('https://slmp.riccardo-morreti.ru/backend/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "class": "TRANSLATE",
                    "type": "GET",
                    "data": {
                        "text": text,
                        "language": language
                    }
                })
            });

            const data = await response.json();  // Теперь это сделано правильно, асинхронно
            const decodedData = JSON.parse(data.return.replace(/\\"/g, '"'));
            console.log(decodedData);
            if (decodedData['destination-text']) {  // Проверьте правильность поля в вашем API-ответе
                console.log(decodedData['destination-text'])
                setTranslatedText(decodedData['destination-text']);
            }
        } catch (error) {
            console.error('Error translating text:', error);
        }
    };

    // Перевод при изменении props 'text' и 'language'
    useEffect(() => {
        translateText();
    }, [text, language]);

    return <div>{translatedText}</div>;
};

export default Translator;