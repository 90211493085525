import React from 'react';
import '../../App.css';
import { retrieveLaunchParams } from '@tma.js/sdk';

import avatar from '../../Images/Avatar.svg';
import geo from '../../Images/Frame 4 (3).png';
import wallet from '../../Images/Frame 4 (4).png';
import catalog from '../../Images/notebook-dynamic-color.png';
import promocode from '../../Images/zoom-dynamic-color.png';
import partner from '../../Images/tick-dynamic-color.png';
import support from '../../Images/Frame 4 (5).png';

function AdminPanel() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });
    
    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (event) => {
        
        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <div className='Page' onClick={redirectToPage}>
            <div className="MainPage-menu gap">
                <div className="container full">
                    <div className="MainPage-menu-item welcome">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={avatar} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    Админ-панель
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                        <div className="text-p">
                                    Управляйте настройками Вашего приложения
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainPage-menu">
                <div className="container full">
                    <div className="MainPage-menu-item welcome">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={catalog} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    Каталог
                                </div>
                                <div className="text-p">
                                    Управляйте настройками Ваших каталогов
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <a href="/adminpanelcashbackerappprivatepagetopsecret/catalog">
                                <div className="button">
                                    Настроить
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainPage-menu gap">
                <div className="container full">
                    <div className="MainPage-menu-item">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={promocode} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    Промокоды
                                </div>
                                <div className="text-p">
                                Управляйте настройками Ваших промокодов
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <div className="button">
                                Настроить
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminPanel;