import React from 'react';
import '../../../App.css';
import Translator from '../../../Components/Translator';
import { retrieveLaunchParams } from '@tma.js/sdk';
import like from '../Images/thumb-up-dynamic-color.svg';
import flash from '../Images/flash-dynamic-color.svg';

function ShopPage() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (categoryName, categoryIndex) => {
        const url = `/shopshow?category=${categoryName}&index=${categoryIndex}`;
        window.location.href = url;
    };

    return (
        <div className='Page'>
            <div className="MainPage-menu">
                <div className="Header">
                    <div className="header-container">
                        <div className="header-container-item"><Translator text="Магазины" language={tLanguage} /></div>
                    </div>
                </div>
                <div className="MainPage-menu gap">
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Косметика', 1)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={like} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Косметика" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow' onClick={redirectToPage}>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Электроника', 2)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Электроника" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Одежда и обувь', 3)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Одежда и обувь" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Аксессуары', 4)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Аксессуары" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Мероприятия', 5)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Мероприятия" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Детям', 6)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Детям" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('18\+', 7)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="18+" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Здоровье', 8)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Здоровье" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Почитать', 9)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Почитать" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Поесть', 10)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Поесть" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Все для дома', 11)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Всё для дома" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container full">
                        <div className="MainPage-menu-item travel" onClick={() => redirectToPage('Разное', 12)}>
                            <div className="icontainer-start-travel">
                                <div className="image">
                                    <img src={flash} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title travel-text">
                                    <Translator text="Разное" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="icontainer-end">
                                <div className="button" data-link='/shopshow'>
                                <Translator text="Посмотреть" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopPage;