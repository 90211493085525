import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';

// pages

import Profile from './Pages/Profile';
import MainPage from './Pages/MainPage';
import CatalogPage from './Pages/CatalogPage';
import TravelPage from './Pages/CatalogPages/TravelPages/TravelPage';
import HotelPage from './Pages/CatalogPages/TravelPages/HotelPage';
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import CatalogAdminPage from './Pages/AdminPanel/CatalogAdminPanel';
import AirPage from './Pages/CatalogPages/TravelPages/AirPage';
import ShopPage from './Pages/CatalogPages/ShopPages/ShopPage';
import ShopShow from './Pages/CatalogPages/ShopPages/ShopShow'

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MainPage />
      },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: '/catalog',
        element: <CatalogPage />
      },
      {
        path: '/travel',
        element: <TravelPage />
      },
      {
        path: '/shop',
        element: <ShopPage />
      },
      {
        path: '/hotels',
        element: <HotelPage />
      },
      {
        path: '/airtickets',
        element: <AirPage />
      },
      {
        path: '/shopshow',
        element: <ShopShow />
      },
      {
        path: '/adminpanelcashbackerappprivatepagetopsecret',
        element: <AdminPanel />
      },
      {
        path: '/adminpanelcashbackerappprivatepagetopsecret/catalog',
        element: <CatalogAdminPage />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);