import React, { useState, useEffect } from 'react';
import Translator from '../Components/Translator';
import { retrieveLaunchParams } from '@tma.js/sdk';

import '../App.css';

import avatar from '../Images/Avatar.svg';
import geo from '../Images/Frame 4 (3).png';
import wallet from '../Images/Frame 4 (4).png';
import catalog from '../Images/notebook-dynamic-color.png';
import promocode from '../Images/zoom-dynamic-color.png';
import partner from '../Images/tick-dynamic-color.png';
import support from '../Images/Frame 4 (5).png';

import onboardingPhoto from '../Images/Group 1462982916.svg';

function MainPage() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    if (tLanguage === 'ru') {
        console.log('Russia');
    } else {
        console.log('Not Russia');
    }

    const [showOnboarding, setShowOnboarding] = useState(true);
    const { HapticFeedback } = window.Telegram.WebApp;
    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const redirectToPage = (event) => {
        const link = event.target.getAttribute('data-link');
        if (link) {
            HapticFeedback.notificationOccurred('success');
            window.location.href = link;
        }
    };

    useEffect(() => {
        const onboardingShown = localStorage.getItem('onboardingShown');
        if (!onboardingShown) {
            setShowOnboarding(true);
            localStorage.setItem('onboardingShown', 'true');
        }
    }, []);

    const handleOnboardingClose = () => {
        setShowOnboarding(false);
    };

    return (
        <div className='Page' onClick={redirectToPage}>
            {showOnboarding && (
                <div className="onboarding-overlay">
                    <div className="onboarding-content">
                        <img src={onboardingPhoto} alt="" />
                        <div className="onboarding-title">
                            <Translator text="Пользуйтесь ботом и зарабатывайте кешбек" language={tLanguage} />
                        </div>
                        <div className="button fullonboard" onClick={handleOnboardingClose}><Translator text="Начать" language={tLanguage} /></div>
                    </div>
                </div>
            )}
            <div className="MainPage-menu gap">
                <div className="container full">
                    <div className="MainPage-menu-item welcome">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={avatar} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    <Translator text="Здравствуйте" language={tLanguage} />, {tName}
                                </div>
                                <div className="text-p">
                                    <Translator text="Добро пожаловать" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="MainPage-menu-item geo">
                            <div className="icontainer-start">
                                <div className="image">
                                    <img src={geo} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title">
                                        <Translator text="ГЕО" language={tLanguage} />
                                    </div>
                                    <div className="text-p">
                                        <Translator text="Выберите ГЕО" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="MainPage-menu-item wallet">
                            <div className="icontainer-start">
                                <div className="image">
                                    <img src={wallet} alt="" />
                                </div>
                                <div className="text">
                                    <div className="text-title">
                                        <Translator text="Кошелёк" language={tLanguage} />
                                    </div>
                                    <div className="text-p">
                                        <Translator text="Ваш кошелёк" language={tLanguage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainPage-menu">
                <div className="container full">
                    <div className="MainPage-menu-item welcome">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={catalog} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    <Translator text="Каталог" language={tLanguage} />
                                </div>
                                <div className="text-p">
                                    <Translator text="Полный список промокодов для Вас" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <a href='/catalog' className="button">
                                <Translator text="Перейти" language={tLanguage} data-link='/catalog' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainPage-menu gap">
                <div className="container full">
                    <div className="MainPage-menu-item">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={promocode} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    <Translator text="Промокоды" language={tLanguage} />
                                </div>
                                <div className="text-p">
                                    <Translator text="Сразу перейдите в Промокоды без дополнительных действий" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <div className="button">
                                <Translator text="Перейти" language={tLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container full">
                    <div className="MainPage-menu-item">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={partner} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    <Translator text="Партнёрская программа" language={tLanguage} />
                                </div>
                                <div className="text-p">
                                    <Translator text="Перейдите для большей информации" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <div className="button">
                                <Translator text="Перейти" language={tLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainPage-menu">
                <div className="container full">
                    <div className="MainPage-menu-item welcome">
                        <div className="icontainer-start">
                            <div className="image">
                                <img src={support} alt="" />
                            </div>
                            <div className="text">
                                <div className="text-title">
                                    <Translator text="Поддержка" language={tLanguage} />
                                </div>
                                <div className="text-p">
                                    <Translator text="Мы всегда готовы Вам помочь" language={tLanguage} />
                                </div>
                            </div>
                        </div>
                        <div className="icontainer-end">
                            <div className="button">
                                <Translator text="Перейти" language={tLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainPage;