import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function Profile() {

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const [balance, setBalance] = useState(999);
    const [friendsCount, setFriendsCount] = useState(25);

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='Профиль' className='Header' />
            <div className="MainPage-menu">
                <div className="MainPage-menu-item-row">
                    <div className="MainPage-menu-item MainPage-menu-item-white">
                        Баланс: {balance} монет
                    </div>
                    <div className="MainPage-menu-item MainPage-menu-item-white">
                        Друзья ({friendsCount})
                    </div>
                </div>
                <div className="MainPage-menu-item MainPage-menu-item-black">
                    Коллекция NFT
                </div>
                <div className="MainPage-menu-item MainPage-menu-item-black">
                    Мои победы
                </div>
            </div>
        </div>
    );
}

export default Profile;