import React from 'react';
import '../App.css';
import Translator from '../Components/Translator';
import { retrieveLaunchParams } from '@tma.js/sdk';

import travel from '../Images/travel-dynamic-color.svg';
import shop from '../Images/dollar-dollar-color.svg';
import marketplace from '../Images/computer-dynamic-color.svg';
import education from '../Images/calculator-dynamic-color.svg';

function CatalogPage() {

    const launchParams = retrieveLaunchParams();
    const tName = launchParams.initData.user.firstName;
    const tLanguage = launchParams.initData.user.languageCode;

    const { SettingsButton } = window.Telegram.WebApp;

    SettingsButton.show();
    window.Telegram.WebApp.onEvent('settingsButtonClicked', function () {
        window.location.href = '/adminpanelcashbackerappprivatepagetopsecret';
    });

    const { HapticFeedback } = window.Telegram.WebApp;
    const { BackButton } = window.Telegram.WebApp;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    const redirectToPage = (event) => {
        const link = event.target.getAttribute('data-link');
        if (link) {
            HapticFeedback.notificationOccurred('success');
            window.location.href = link;
        }
    };

    return (
        <div className='Page' onClick={redirectToPage}>
            <div className="MainPage-menu">
                <div className="Header">
                    <div className="header-container">
                        <div className="header-container-item">Каталог</div>
                    </div>
                </div>
                <div className="MainPage-menu gap">
                    <div className="container">
                        <div className="MainPage-menu-item catalog">
                            <div className="image">
                                <img src={travel} alt="" />
                            </div>
                            <div className="desc">
                                <div className="text-title-catalog">Путешествия</div>
                                <div className="text-catalog">Программы связанные с путешествиями</div>
                            </div>
                            <div className="button full" data-link='/travel' >
                                Перейти
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="MainPage-menu-item catalog">
                            <div className="image">
                                <img src={shop} alt="" />
                            </div>
                            <div className="desc">
                                <div className="text-title-catalog">Магазины</div>
                                <div className="text-catalog">Шоппинг и что то то еще, какие магазины?</div>
                            </div>
                            <div className="button full" data-link='/shop'>
                                Перейти
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="MainPage-menu-item catalog">
                            <div className="image">
                                <img src={marketplace} alt="" />
                            </div>
                            <div className="desc">
                                <div className="text-title-catalog">Маркетплейсы</div>
                                <div className="text-catalog">Онлайн шоппинг и крупные маркетплейсы</div>
                            </div>
                            <div className="button full">
                                Скоро
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="MainPage-menu-item catalog">
                            <div className="image">
                                <img src={education} alt="" />
                            </div>
                            <div className="desc">
                                <div className="text-title-catalog">Образование</div>
                                <div className="text-catalog">Программы связанные с образованием</div>
                            </div>
                            <div className="button full">
                                Скоро
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatalogPage;