import React from 'react';

import scissors from '../Images/scissors-svgrepo-com.svg';

function Header({ title }) {
    return (
        <div className='Header'>
            <div className="image">
                <img src={scissors} alt="" />
            </div>
            <div className="title">
                {title}
            </div>
        </div>
    )
}

export default Header